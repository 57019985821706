<script setup>
import { refDebounced, syncRef } from '@vueuse/core';
import { useProjectsV3Loader } from '@/api';
import { useI18n, useItemCache } from '@/util';
import { normalizeFilterIds } from './normalize';
import { useFilter, useFilterChips, useFilterClear, useFilterCount, useFilterNormalize } from './useFilter';

const props = defineProps({
  name: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    required: true,
  },
  loaderParams: {
    type: Object,
    default: () => ({}),
  },
  defaultValue: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  multiple: {
    type: Boolean,
    default: true,
  },
});

const { t } = useI18n();
const { params, activeFilter, dataIdentifierPrefix } = useFilter();
const searchTerm = shallowRef('');
const debouncedSearchTerm = refDebounced(searchTerm, 300);
const count = shallowRef(10);
const filterType = 'projects';
const baseParams = {
  'fields[projects]': 'id,name',
  include: 'projects',
};

const pageSize = 20;
const state = useProjectsV3Loader({
  params: computed(() => ({
    ...baseParams,
    ...props.loaderParams,
    searchTerm: debouncedSearchTerm.value,
  })),
  count,
  pageSize,
});

const { items } = state;

const missingProjectIds = shallowRef([]);
const missingProjectsState = useProjectsV3Loader({
  params: computed(() => ({
    ...baseParams,
    ...props.loaderParams,
    projectIds: missingProjectIds.value.join(','),
  })),
  count: computed(() => missingProjectIds.value.length || -1),
  pageSize: 50,
});

const { computeAll, computeMissing } = useItemCache(
  computed(() => Object.values(activeFilter.value?.included?.[filterType] || {})),
  items,
  missingProjectsState.items,
);

const projectIds = computed({
  get() {
    return typeof params.value[props.name] === 'string'
      ? params.value[props.name].split(',').map(Number).filter(Boolean)
      : [];
  },
  set(value) {
    params.value = {
      ...params.value,
      [props.name]: value.join(','),
    };
  },
});

const projects = computeAll(projectIds, (id) => ({ id, name: t('Unknown') }));

syncRef(computeMissing(projectIds), missingProjectIds, { direction: 'ltr' });

useFilterNormalize(toRef(props, 'name'), (value) => normalizeFilterIds(value, props.defaultValue));

useFilterClear(toRef(props, 'name'), toRef(props, 'defaultValue'));

useFilterChips(
  computed(() =>
    projects.value.map((project) => ({
      name: project.name,
      icon: 'lsi-project',
      type: 'project',
      delete: () => {
        projectIds.value = projectIds.value.filter((id) => id !== project.id);
      },
    })),
  ),
);

useFilterCount(computed(() => projectIds.value.length));

function onClick({ id }) {
  if (projectIds.value.includes(id)) {
    projectIds.value = projectIds.value.filter((projectId) => projectId !== id);
  } else if (props.multiple) {
    projectIds.value = [...projectIds.value, id];
  } else {
    projectIds.value = [id];
  }
}

function icon(id) {
  return projectIds.value.includes(id) ? 'lsi-remove' : 'lsi-add';
}
</script>
<template>
  <WidgetMenu :closeOnContentClick="false" location="bottom left" offset="12">
    <template #activator="activator">
      <LswFilterOptionButton
        v-bind="activator.props"
        :ariaExpanded="activator.isActive"
        :ariaPressed="projectIds.length > 0"
        icon="lsi-project"
        :data-identifier="`${dataIdentifierPrefix}-project-picker-button`"
        :disabled="disabled"
      >
        {{ title }}
      </LswFilterOptionButton>
    </template>
    <VCard v-bind="VCardRounded" class="w-60">
      <div class="pl-2 pr-2">
        <VTextField
          v-model.trim="searchTerm"
          v-bind="VTextFieldFilter"
          class="mt-2 !min-w-full"
          :placeholder="t('Search projects')"
          prependInnerIcon="lsi-search"
          :active="Boolean(searchTerm)"
        />
      </div>
      <WidgetLoadingState :state="state" :blankTitle="t('No projects found')">
        <template #default>
          <VList density="compact" maxHeight="384">
            <template v-for="project in items" :key="project.id">
              <VListItem
                :active="projectIds.includes(project.id)"
                class="my-1 rounded-sm"
                :data-identifier="`${dataIdentifierPrefix}-project-picker-item`"
                @click="onClick(project)"
              >
                <div class="flex items-center justify-between text-body-1">
                  <LscOverflowEllipsis class="ml-2 max-w-32 text-body-1">
                    {{ project.name }}
                  </LscOverflowEllipsis>
                  <LscIcon v-if="multiple" :icon="icon(project.id)" class="text-icon-subtle" size="sm" />
                </div>
              </VListItem>
            </template>
          </VList>
        </template>
        <!-- Return empty blank state as per design -->
        <template #blank>
          <div class="mb-2" />
        </template>
      </WidgetLoadingState>
      <WidgetLoadingLazy v-model:count="count" :state="state" :step="pageSize" />
    </VCard>
  </WidgetMenu>
</template>
