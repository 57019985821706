<script setup>
import { useDebounce } from '@vueuse/core';
import { useJobRolesV3Loader, useLoadingCount, useJobRoleActions, getJobRoleSuggestions } from '@/api';
import { useI18n } from '@/util';
import LscSkeleton from '../../../components/infodisplay/skeleton/LscSkeleton.vue';
import LswRolePickerItem from './LswRolePickerItem.vue';

defineProps({
  dataTestIdPrefix: {
    type: String,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(['addRole']);

const roles = defineModel('roles', {
  type: Array,
  default: () => [],
});

const { t } = useI18n();
const count = shallowRef(30);
const searchTerm = shallowRef('');
const suggestions = getJobRoleSuggestions(searchTerm, 5);

const jobRoleActions = useJobRoleActions();

const debouncedSearchTerm = useDebounce(
  computed(() => searchTerm.value),
  300,
);

const state = useJobRolesV3Loader({
  count,
  params: computed(() => ({
    searchTerm: debouncedSearchTerm.value,
  })),
});

const { items } = state;

const loadingCount = useLoadingCount({ state, count });

function selectRole(role) {
  roles.value = [role];
}

function isRoleSelected(role) {
  return roles.value.some((selectedRole) => selectedRole.id === role.id);
}

function addRole(event) {
  emit('addRole', event);
}

async function createRole(suggestion) {
  const createdRole = await jobRoleActions.createJobRole({
    name: suggestion,
  });

  roles.value = [createdRole];
}
</script>

<template>
  <LscSheet class="flex w-72 flex-col !p-0">
    <LscSearchBar
      v-model.trim="searchTerm"
      :placeholder="t('Search roles')"
      class="mx-4 mb-2 mt-4"
      prependInnerIcon="lsi-search"
      clearable
      autofocus
      @click.stop
    />
    <div class="flex h-full flex-col items-stretch gap-1 overflow-hidden" role="listbox">
      <WidgetLoadingState :state="state">
        <template #defaultOrLoading>
          <div class="max-h-80 flex-auto overflow-y-auto px-2">
            <ul class="flex flex-col items-stretch gap-1" role="listbox">
              <LswRolePickerItem
                v-for="role in items"
                :key="role.id"
                :active="isRoleSelected(role)"
                :dataTestIdPrefix="dataTestIdPrefix"
                @click="selectRole(role)"
              >
                {{ role.name }}
              </LswRolePickerItem>
              <li v-for="i in loadingCount" :key="i" class="flex h-10 shrink-0 items-center gap-2 px-2">
                <LscSkeleton class="h-3 w-full rounded-full" />
              </li>
              <WidgetLoadingLazy v-model:count="count" :state="state" margin="150px" :minCount="10" />
            </ul>
          </div>
        </template>
        <template #blank>
          <LscEmptyState v-if="searchTerm" class="mb-4" :title="t('No roles match your search')" size="sm" />

          <div v-else>
            <div class="px-4 text-subtle">{{ t("Let's start adding job roles to your account") }}</div>

            <h4 class="px-4 py-2 font-semibold">{{ t('Suggestions') }}</h4>
            <div class="max-h-80 flex-auto overflow-y-auto px-2">
              <ul class="flex flex-col items-stretch gap-1" role="listbox">
                <LswRolePickerItem
                  v-for="(suggestion, index) in suggestions"
                  :key="index"
                  :dataTestIdPrefix="dataTestIdPrefix"
                  @click="createRole(suggestion)"
                >
                  {{ suggestion }}

                  <template #append>
                    <LscButton
                      size="sm"
                      variant="plain-primary"
                      prependIcon="lsi-add"
                      @click.stop="createRole(suggestion)"
                    >
                      {{ t('Add') }}
                    </LscButton>
                  </template>
                </LswRolePickerItem>
              </ul>
            </div>
          </div>
        </template>
      </WidgetLoadingState>
    </div>
    <div class="mx-4 mb-4 mt-2 flex items-center">
      <LscButton variant="plain-primary" prependIcon="lsi-add" @click.stop="addRole">
        {{ t('Add role') }}
      </LscButton>
    </div>
  </LscSheet>
</template>
