<script setup>
import { useIntersectionObserver, useResizeObserver } from '@vueuse/core';
import { provideLscTable } from './useLscTable';
import LscTableHeader from './LscTableHeader.vue';
import { LscTableDensities } from './constants';

const props = defineProps({
  /**
   * The density of the rows.
   * @type {PropType<typeof LscTableDensities[number]>}
   */
  density: {
    type: String,
    default: 'md',
    validator: (v) => LscTableDensities.includes(v),
  },
  /**
   * Whether there is a border between the columns.
   * @type {PropType<Boolean>}
   */
  dividers: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the column widths are proportional to their container, the widths are a suggestion but they can be larger.
   * @note Please only use this if the table is not resizable & you have a very small number of columns.
   * @type {PropType<Boolean>}
   */
  proportional: {
    type: Boolean,
    default: false,
  },
  /**
   * Show the header at the top of the table. Defaults to true.
   * @type {PropType<Boolean>}
   */
  showHeader: {
    type: Boolean,
    default: true,
  },
  /**
   * Whether the first column sticks to the left of the scrolling container.
   * @deprecated Use `stickyFirstColumns` instead.
   * @type {PropType<Boolean>}
   */
  stickyFirstColumn: {
    type: Boolean,
    default: false,
  },
  /**
   * The number of columns that stick to the left of the scrolling container.
   * @type {PropType<Number>}
   */
  stickyFirstColumns: {
    type: Number,
    default: 0,
    validator: (value) => value >= 0,
  },
  /**
   * Whether the footer sticks to the bottom of the scrolling container.
   * @type {PropType<Boolean>}
   */
  stickyFooter: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the header sticks to the top of the scrolling container.
   * @type {PropType<Boolean>}
   */
  stickyHeader: {
    type: Boolean,
    default: false,
  },
  /**
   * Whether the last column sticks to the right of the scrolling container.
   * @deprecated Use `stickyLastColumns` instead.
   * @type {PropType<Boolean>}
   */
  stickyLastColumn: {
    type: Boolean,
    default: false,
  },
  /**
   * The number of columns that stick to the right of the scrolling container.
   * @type {PropType<Number>}
   */
  stickyLastColumns: {
    type: Number,
    default: 0,
    validator: (value) => value >= 0,
  },
});

/**
 * A list of column objects.
 * @type {ModelRef<LscTableColumn[]>}
 */
const columns = defineModel('columns', {
  type: Array,
  required: true,
  validator: (inputColumns) => {
    if (!Array.isArray(inputColumns)) {
      return false;
    }

    for (const column of inputColumns) {
      const slot = column.slot ?? column.id;
      if (slot === 'default' || slot === 'dragTargetIndicator') {
        // eslint-disable-next-line no-console
        console.warn(`LscTable.columns['${column.id}']: Restricted slot name: "${slot}"`);
        return false;
      }
    }

    return true;
  },
});

/**
 * An object that accepts orderBy and orderMode properties.
 * @type {ModelRef<LscTableOrder>}
 */
const order = defineModel('order', {
  type: Object,
  default: null,
  validator: (value) => value === null || (value.orderBy && value.orderMode),
});

// TODO: Remove deprecated props in the future
const stickyFirstColumns = computed(() => {
  if (props.stickyFirstColumns > 0) {
    return props.stickyFirstColumns;
  }
  return props.stickyFirstColumn ? 1 : 0;
});
const stickyLastColumns = computed(() => {
  if (props.stickyLastColumns > 0) {
    return props.stickyLastColumns;
  }
  return props.stickyLastColumn ? 1 : 0;
});

const { calculateGridColumn, columns: resolvedColumns } = provideLscTable({
  columns,
  order,
  proportional: computed(() => props.proportional),
  stickyFirstColumns,
  stickyLastColumns,
});

// Scroll shadow effects
const tableShadowStartSentinelRef = shallowRef();
const tableShadowEndSentinelRef = shallowRef();

const shadowStart = shallowRef(false);
const shadowEnd = shallowRef(false);

useIntersectionObserver(
  computed(() => (stickyFirstColumns.value > 0 ? tableShadowStartSentinelRef.value : undefined)),
  (entries) => {
    shadowStart.value = !entries.at(-1).isIntersecting;
  },
  { threshold: 1 },
);

useIntersectionObserver(
  computed(() => (stickyLastColumns.value > 0 ? tableShadowEndSentinelRef.value : undefined)),
  (entries) => {
    shadowEnd.value = !entries.at(-1).isIntersecting;
  },
  { threshold: 1 },
);

const gridColumns = computed(() => resolvedColumns.value.map(calculateGridColumn).join(' '));

const tableRef = shallowRef();
// eslint-disable-next-line lightspeed/prefer-shallow-ref
const colRefs = ref([]);
const colWidths = shallowRef([]);

function calculateColumnWidths() {
  colWidths.value = colRefs.value.map((col) => col.getBoundingClientRect().width);
}

useResizeObserver(
  computed(() => (props.proportional ? tableRef.value : undefined)),
  calculateColumnWidths,
);

onMounted(calculateColumnWidths);

const style = computed(() => {
  const styleObject = {
    '--lsc-table-grid-cols': gridColumns.value,
  };

  let startOffset = 0;
  for (let i = 0; i < stickyFirstColumns.value; i += 1) {
    styleObject[`--lsc-table-col-offset-${i}`] = `${startOffset}px`;
    startOffset += colWidths.value[i];
  }

  let endOffset = 0;
  for (let i = 0; i < stickyLastColumns.value; i += 1) {
    const index = colWidths.value.length - 1 - i;
    styleObject[`--lsc-table-col-offset-${index}`] = `${endOffset}px`;
    endOffset += colWidths.value[index];
  }

  return styleObject;
});
</script>

<template>
  <table
    ref="tableRef"
    class="group/LscTable relative grid min-w-full grid-cols-[--lsc-table-grid-cols]"
    :style="style"
    :data-dividers="dividers"
    :data-density="density"
    :data-sticky-header="stickyHeader"
    :data-sticky-footer="stickyFooter"
    :data-shadow-start="shadowStart"
    :data-shadow-end="shadowEnd"
  >
    <colgroup class="col-span-full grid grid-cols-subgrid">
      <col v-for="column in resolvedColumns" :key="column.id" ref="colRefs" />
    </colgroup>
    <!-- These elements are to control the scroll shadow effects -->
    <tbody class="sticky inset-0 col-span-full" role="presentation">
      <tr role="presentation">
        <td ref="tableShadowStartSentinelRef" role="presentation" class="absolute left-0" />
        <td ref="tableShadowEndSentinelRef" role="presentation" class="absolute right-0" />
      </tr>
    </tbody>
    <thead
      v-if="showHeader"
      :class="[
        'relative z-[1]',
        'col-span-full grid grid-cols-subgrid',
        'group-data-[sticky-header=true]/LscTable:sticky',
        'group-data-[sticky-header=true]/LscTable:top-0',
        'group-data-[sticky-header=true]/LscTable:z-[5]',
      ]"
    >
      <slot name="header">
        <LscTableHeader />
      </slot>
    </thead>
    <slot name="body">
      <tbody class="col-span-full grid grid-cols-subgrid">
        <slot name="default" />
      </tbody>
    </slot>

    <tfoot
      :class="[
        'relative z-[2] -mt-px',
        'col-span-full grid grid-cols-subgrid',
        'group-data-[sticky-footer=true]/LscTable:sticky',
        'group-data-[sticky-footer=true]/LscTable:bottom-0',
      ]"
    >
      <slot name="footer" />
    </tfoot>
  </table>
</template>
