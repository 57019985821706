import { useAxios } from '../base/useAxios';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';
import { useI18n } from '@/util';

export function useTagActions() {
  const { t } = useI18n();
  const api = useAxios();
  const { emit: emitRealTimeUpdate, socketId } = useRealTimeUpdates();
  const { emit: emitOptimisticUpdate } = useOptimisticUpdates();

  return {
    /**
     * @param {{ name: string, color: string, projectId: number | 0 }} tag
     */
    createTag(tag) {
      const promise = api
        .post('/tags.json', tag, {
          errorMessage(error) {
            if (error.response?.status === 409) {
              return t('A tag with this name already exists');
            }
            return t('Failed to create the tag');
          },
          headers: { 'Socket-ID': socketId },
        })
        .then((response) => {
          emitRealTimeUpdate({
            type: 'tag',
            action: 'new',
            userId: response.data.id,
          });

          return response;
        });
      return promise;
    },
    /**
     *
     * @param {{ id: number, name: string, color: string, projectId: number | 0 }} tag
     */
    updateTag(tag) {
      const promise = api
        .put(
          `/tags/${tag.id}.json`,
          { tag },
          {
            errorMessage: t('Failed to update the tag'),
            headers: { 'Socket-ID': socketId },
          },
        )
        .then(() => {
          emitRealTimeUpdate({
            type: 'tag',
            action: 'edited',
            tagId: tag.id,
          });
        });

      emitOptimisticUpdate({
        type: 'tag',
        action: 'update',
        tag,
        promise,
      });

      return promise;
    },
  };
}
