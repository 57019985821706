import { useListLoader } from '../base/useListLoader';
import { useRealTimeUpdates } from '../base/useRealTimeUpdates';
import { useOptimisticUpdates } from '../base/useOptimisticUpdates';

function getClientsCustomFields({ customfields: customFields, customfieldCompanies }) {
  const clientsCustomFields = {};

  if (customfieldCompanies) {
    Object.entries(customfieldCompanies).forEach(([id, { companyId, customfieldId, value }]) => {
      if (!clientsCustomFields[companyId]) {
        clientsCustomFields[companyId] = {};
      }

      const { type, options } = customFields[customfieldId];
      const clientCustomField = clientsCustomFields[companyId];
      clientCustomField[customfieldId] = { id: Number(id), type, value };

      switch (type) {
        case 'status':
        case 'dropdown': {
          const option = options.choices.find((el) => el.value === value);
          if (option) {
            clientCustomField[customfieldId].color = option.color;
          }
          break;
        }
        default:
          break;
      }
    });
  }

  return clientsCustomFields;
}

function responseToItems({ data: { companies, included } }) {
  const clientsCustomFields = getClientsCustomFields(included);

  companies.forEach((el) => {
    /* eslint-disable no-param-reassign */
    el.country = included.countries?.[el.countryCode] || null;
    el.industry = included.industries?.[el.industryId] || null;
    el.tags.forEach((tag, i) => {
      const { id, color, name } = included.tags[tag.id];
      el.tags[i] = { id, color, name };
    });
    el.health = included.updates?.[el.companyUpdate?.id] || null;
    el.owner = included.users?.[el.clientManagedBy?.id] || null;
    el.projectCount = el.stats?.projectCount;
    el.taskCount = el.stats?.taskCount;
    el.unreadEmailCount = el.stats?.unreadEmailCount;
    el.customFields = clientsCustomFields[el.id];
    /* eslint-enable no-param-reassign */
  });

  return companies;
}

function responseToMeta({ data: { meta } }) {
  return { totalCount: meta.page?.count };
}

/**
 * Loads clients from Teamwork v3 endpoints.
 */
export function useClientsV3Loader({ params, count, pageSize }) {
  const { state, refresh, update } = useListLoader({
    url: '/projects/api/v3/companies.json',
    params,
    count,
    pageSize,
    responseToItems,
    responseToMeta,
    type: 'companies',
  });

  useRealTimeUpdates((event) => {
    switch (event.type) {
      case 'company':
        refresh();
        break;
      default:
        break;
    }
  });

  useOptimisticUpdates((event) => {
    const { type, action, promise } = event;

    if (type === 'company' && action === 'update') {
      const { client } = event;
      update((clients) => clients.map((el) => (el.id === client.id ? { ...el, ...client } : el)), promise);
    }

    if (type === 'clientCustomField') {
      const { clientCustomField, clientId } = event;
      const { customFieldId } = clientCustomField;

      update(
        (clients) =>
          clients.map((el) => {
            if (el.id === clientId) {
              const { customFields } = el;
              if (customFields && (action === 'update' || action === 'create')) {
                customFields[customFieldId] = clientCustomField;
              } else if (action === 'delete') {
                delete customFields[customFieldId];
              }
              return { ...el, customFields };
            }
            return el;
          }),
        promise,
      );
    }
  });

  return state;
}
