<script setup>
import { useI18n } from '@/util';
import ReportBuilderOptions from './options/ReportBuilderOptions.vue';
import ReportBuilderPreview from './preview/ReportBuilderPreview.vue';
import { provideReportBuilder } from './useReportBuilder.js';
import { useReportTracking } from '../useReportTracking';
import { FeedbackButton } from '@/module/feedback';

const props = defineProps({
  report: {
    type: Object,
    default: () => ({}),
  },
});

const emit = defineEmits(['updated', 'close', 'handleCustomReportTrack']);

const { t } = useI18n();
const toast = useLsToast();
const { trackReportsEvent } = useReportTracking();

const { saveReport, selectedColumns } = provideReportBuilder(toRef(props, 'report'));

const isEditing = computed(() => Boolean(props.report.id));
const isSaving = shallowRef(false);

const actionText = computed(() => {
  return isEditing.value ? t('Update report') : t('Save report');
});

const hasSelectedColumns = computed(() => selectedColumns.value.length > 0);

async function onClickSaveOrEditReport() {
  emit('handleCustomReportTrack', 'custom_report_modal', 'save_custom_report_clicked', 'advanced_discovery_event');
  if (isSaving.value) {
    return Promise.resolve();
  }

  const promise = saveReport() || Promise.reject();

  isSaving.value = true;

  return promise
    .then((status) => {
      if (status === false) {
        isSaving.value = false;
        return;
      }
      // @todo be issue?
      // after saving we trigger a refresh of the report
      // but the api is returning a 423 error if we do it too fast
      // so we wait a bit before triggering the refresh
      setTimeout(() => {
        if (isEditing.value) {
          toast.success(t('Regenerating custom report'));
          emit('updated');
        }

        emit('close');
        isSaving.value = false;
      }, 2000);
      trackReportsEvent('reports', 'custom_report_created', 'activation', 'custom_reports');
    })
    .catch(() => {
      isSaving.value = false;
    });
}

function handleClose() {
  emit('close');
}

function handleCustomReportTrack(pageType, eventAction, eventCategory, ...args) {
  emit('handleCustomReportTrack', pageType, eventAction, eventCategory, ...args);
}
</script>

<template>
  <LscDialogCard size="full" fullScreen :title="t('Custom report builder')" @close="handleClose">
    <template #prependHeaderRight>
      <FeedbackButton data-identifier="feedback-custom-reports-builder" />
    </template>
    <template #content>
      <section class="flex h-full w-full items-stretch overflow-hidden text-body-1">
        <ReportBuilderOptions
          class="border-r border-t border-separator"
          @handleCustomReportTrack="handleCustomReportTrack"
        />
        <div class="flex w-full flex-1 flex-col overflow-hidden border-t border-separator bg-surface-default">
          <ReportBuilderPreview />

          <div class="flex shrink-0 self-end pb-6 pr-6">
            <LscButton
              v-LsdTooltip="hasSelectedColumns ? undefined : t('A name and at least one column are necessary')"
              :disabled="!hasSelectedColumns"
              :loading="isSaving"
              variant="primary"
              size="lg"
              @click="onClickSaveOrEditReport"
            >
              {{ actionText }}
            </LscButton>
          </div>
        </div>
      </section>
    </template>
  </LscDialogCard>
</template>
