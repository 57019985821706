import { DateTime } from 'luxon';

const itemTypes = {
  users: 'users',
  teams: 'teams',
  companies: 'companies',
};

export function normalizeUsers(items, users, companies, teams) {
  if (items == null) {
    return undefined;
  }
  return items
    .map(({ id, type }) => {
      if (!id) {
        return null;
      }
      if (type === itemTypes.users && users[id]) {
        return {
          ...users[id],
          assigneeType: itemTypes.users,
          entityType: 'user',
        };
      }
      if (type === itemTypes.companies && companies[id]) {
        return {
          ...companies[id],
          assigneeType: itemTypes.companies,
          entityType: 'company',
        };
      }
      if (type === itemTypes.teams && teams[id]) {
        return {
          ...teams[id],
          assigneeType: itemTypes.teams,
          entityType: 'team',
        };
      }
      return null;
    })
    .filter(Boolean);
}

export const normalizeTasks = (_tasks, included = {}) => {
  const {
    projects = {},
    projectPermissions = {},
    tasklists = {},
    users = {},
    companies = {},
    teams = {},
    timeTotals = {},
    tags = {},
    taskSequences = {},
    cards = {},
    columns = {},
    stages = {},
    workflows = {},
    comments = {},
    subtaskStats = {},
    lockdowns = {},
    customfieldTasks: _customfieldTasks = {},
    customfields = {},
    tasks = {}, // holds parentTasks etc.
  } = included;

  const customfieldTasks = Object.values(_customfieldTasks);
  const commentValues = Object.values(comments);
  const permissions = Object.values(projectPermissions)[0];

  return _tasks.map((task) => ({
    ...task,
    projectPermissions: task.projectPermissions || permissions,
    description: task.description ?? '',
    startDate: task.startDate ? DateTime.fromISO(task.startDate) : null,
    dueDate: task.dueDate ? DateTime.fromISO(task.dueDate) : null,
    createdAt: task.createdAt ? DateTime.fromISO(task.createdAt) : null,
    updatedAt: task.updatedAt ? DateTime.fromISO(task.updatedAt) : null,
    completedOn: task.completedOn ? DateTime.fromISO(task.completedOn) : null,
    originalDueDate: task.originalDueDate ? DateTime.fromISO(task.originalDueDate) : null,
    dateLastModified: task.dateLastModified ? DateTime.fromISO(task.dateLastModified) : null,
    ...(tags && task.tagIds && task.tagIds.length > 0
      ? { tags: task.tagIds.filter((id) => id in tags).map((id) => tags[id]) }
      : {}),
    ...(lockdowns && task.lockdown && lockdowns[task.lockdown.id] ? { lockdown: lockdowns[task.lockdown.id] } : {}),
    ...(timeTotals && timeTotals[task.id] ? { timeTotals: timeTotals[task.id] } : {}),
    ...(taskSequences && task.sequenceId ? { sequence: taskSequences[task.sequenceId] } : {}),
    ...(cards && columns && task.card && cards[task.card.id]
      ? {
          column: columns[cards[task.card.id].column.id],
          card: {
            ...cards[task.card.id],
            column: columns[cards[task.card.id].column.id],
          },
        }
      : {}),
    ...(comments ? { comments: commentValues.filter((comment) => comment.objectId === task.id) } : {}),
    ...(task.completedBy && users[task.completedBy] ? { completedBy: users[task.completedBy] } : {}),
    ...(task.createdBy && users[task.createdBy] ? { createdBy: users[task.createdBy] } : {}),
    ...(task.updatedBy && users[task.updatedBy] ? { updatedBy: users[task.updatedBy] } : {}),
    assignees: normalizeUsers(task.assignees, users, companies, teams),
    changeFollowers: normalizeUsers(task.changeFollowers, users, companies, teams),
    commentFollowers: normalizeUsers(task.commentFollowers, users, companies, teams),

    customfieldValues: customfieldTasks.reduce((acc, field) => {
      if (field.taskId === task.id) {
        acc[field.customfieldId] = {
          ...customfields[field.customfieldId],
          value: field.value,
          customfieldId: field.id,
        };
      }
      return acc;
    }, {}),

    // TODO: Ask Greg - does this filter need optimizing? - Topper
    // TODO? improve typing
    ...(customfieldTasks
      ? {
          customFieldValues: Object.fromEntries(
            customfieldTasks
              .filter((field) => field.taskId === task.id)
              .map((customFieldTaskInfo) => [customFieldTaskInfo.customfield.id, customFieldTaskInfo.value]),
          ),
        }
      : {}),

    ...(() => {
      if (task.tasklistId && tasklists[task.tasklistId]) {
        const taskList = tasklists[String(task.tasklistId)];

        const added = {
          taskListId: task.tasklistId,
          taskList, // NOTE: notice the camelCase
          tasklist: taskList,
        };

        const project = projects[taskList.projectId];

        const company = companies[project.companyId];

        if (project) {
          const baseProject = {
            ...project,
            projectPermissions: permissions,
          };

          return {
            ...added,
            project: company
              ? {
                  ...baseProject,
                  company,
                }
              : baseProject,
            projectId: taskList.projectId,
            tasklist: {
              ...taskList,
              baseProject,
            },
          };
        }

        return added;
      }

      return {};
    })(),

    ...(task.parentTaskId > 0 && tasks[task.parentTaskId]
      ? {
          parentTask: tasks[task.parentTaskId],
        }
      : {}),

    subtaskStats: subtaskStats[task.id],
    relatedTasks: tasks,

    workflowStages:
      task.workflowStages?.map((wf) => ({
        ...wf,
        workflow: workflows[wf.workflowId],
        stage: stages[wf.stageId],
      })) ?? [],
  }));
};

export const computeDisplayOrder = (task, tasks) => {
  if (task.positionAfterTaskId != null) {
    const previousTask = tasks.find(({ id }) => id === task.positionAfterTaskId);
    if (previousTask) {
      return previousTask.displayOrder + 0.5;
    }
    return Number.MIN_SAFE_INTEGER;
  }
  return task.displayOrder ?? Number.MAX_SAFE_INTEGER;
};

export const normalizeTask = (inputTask, tasks) => {
  const newTask = { ...inputTask };
  newTask.id ??= -1;
  newTask.parentTaskId ??= 0;
  // TODO taskListId is deprecated - remove when no longer used
  newTask.tasklistId ??= newTask.taskListId ?? 0;
  // TODO taskListId is deprecated - remove when no longer used
  newTask.taskListId = newTask.tasklistId;
  newTask.projectId ??= 0;
  newTask.assignees ??= [];
  // TODO use current user
  newTask.createdBy ??= {};
  newTask.priority ??= '';
  newTask.tags ??= [];
  newTask.displayOrder = computeDisplayOrder(newTask, tasks);

  // TODO taskList is deprecated - remove when no longer used
  newTask.tasklist ??= newTask.taskList;

  // Try to reuse the actual project and tasklist
  // which are included in the already loaded tasks.
  if (newTask.tasklist == null) {
    for (let i = 0; i < tasks.length; i += 1) {
      const task = tasks[i];
      if (task.tasklistId === newTask.tasklistId) {
        newTask.tasklist = task.tasklist;
        newTask.project ??= task.project;
        break;
      }
    }
  }

  // Fall back to a placeholder task list.
  newTask.tasklist ??= {
    id: newTask.tasklistId,
    name: '',
    displayOrder: 0,
  };

  // TODO taskList is deprecated - remove when no longer used
  newTask.taskList = newTask.tasklist;

  // Fall back to a placeholder project.
  newTask.project ??= {
    id: newTask.projectId,
    name: '',
  };

  return newTask;
};

export const normalizeParams = (params) => {
  const normalizedParams = { ...unref(params) };

  normalizedParams.include = [
    ...new Set(
      (normalizedParams.include || '')
        .split(',')
        .map((item) => item.trim())
        .filter(Boolean)
        .concat(['taskLists', 'taskListNames', 'projectNames', 'projects']),
    ),
  ].join(',');

  return normalizedParams;
};
