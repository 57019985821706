import { inject, provide } from 'vue';

const symbol = Symbol('useFilter');
const privateSymbol = Symbol('useFilter/private');

/**
 * @callback NormalizeFunction
 * @param {any} value The value of the GET parameter.
 * @param {string} key The name of the GET parameter.
 * @param {Record<string, any>} params All GET parameters.
 */

export function provideFilter({ params, activeFilter, clearSavedfilter, dataIdentifierPrefix }) {
  const chipList = shallowRef([]);
  const counts = shallowRef([]);
  /** @type {NormalizeFunction[]} */
  const normalizeFunctions = [];
  const defaultValues = [];
  let lastNormalizedParams;

  const api = {
    params,
    activeFilter,
    clearSavedfilter,
    dataIdentifierPrefix: computed(() => `${dataIdentifierPrefix.value}-filter`),
    count: computed(() => counts.value.reduce((total, { value }) => total + value, 0)),
    chips: computed(() => chipList.value.flatMap((c) => c.value)),
    normalize() {
      // Avoid unnecessary work.
      if (params.value === lastNormalizedParams) {
        return;
      }

      let hasChanged = false;
      const newParams = {};

      Object.keys(params.value).forEach((key) => {
        const normalizeFunction = normalizeFunctions.find(({ name }) =>
          typeof name.value === 'string' ? name.value === key : name.value.test?.(key),
        )?.normalize;
        if (normalizeFunction) {
          const value = params.value[key];
          const newValue = normalizeFunction(value, key, params.value);
          newParams[key] = newValue;
          if (newValue !== value) {
            hasChanged = true;
          }
        } else {
          hasChanged = true;
        }
      });

      if (hasChanged) {
        // eslint-disable-next-line no-param-reassign
        params.value = newParams;
      }

      lastNormalizedParams = params.value;
    },
    clear() {
      const clearedParams = { ...params.value };

      Object.keys(params.value).forEach((key) => {
        const item = defaultValues.find(({ name }) =>
          typeof name.value === 'string' ? name.value === key : name.value.test?.(key),
        );
        if (item) {
          clearedParams[key] = unref(item.defaultValue);
        }
      });

      // eslint-disable-next-line no-param-reassign
      params.value = clearedParams;
    },
  };

  provide(symbol, api);
  provide(privateSymbol, { chipList, counts, normalizeFunctions, defaultValues });

  return api;
}

/**
 * @type {Filter}
 */
export function useFilter() {
  return inject(symbol);
}

export function useFilterCount(count) {
  const { counts } = inject(privateSymbol);
  const countRef = computed(() => unref(count));

  counts.value = counts.value.concat([countRef]);

  onScopeDispose(() => {
    // eslint-disable-next-line vue/no-ref-as-operand
    counts.value = counts.value.filter((c) => c !== countRef);
  });
}

export function useFilterChips(chips) {
  const { chipList } = inject(privateSymbol);
  const chipsRef = computed(() => unref(chips));

  chipList.value = chipList.value.concat(chipsRef);

  onUnmounted(() => {
    // eslint-disable-next-line vue/no-ref-as-operand
    chipList.value = chipList.value.filter((c) => c !== chipsRef);
  });
}

/**
 * Registers a function to normalize params.
 * @param {MaybeRef<String|RegExpConstructor>} name Matches the name of a GET parameter.
 * @param {NormalizeFunction} normalize Returns a normalized value of a GET parameter.
 */
export function useFilterNormalize(name, normalize) {
  const { normalizeFunctions } = inject(privateSymbol);
  const nameRef = computed(() => unref(name));

  if (typeof nameRef.value !== 'string' && !(nameRef.value instanceof RegExp)) {
    // eslint-disable-next-line no-console
    console.warn(`useFilterNormalize: invalid filter param: ${nameRef.value}`);
  }

  const item = { name: nameRef, normalize };

  normalizeFunctions.push(item);

  onUnmounted(() => {
    const index = normalizeFunctions.indexOf(item);
    normalizeFunctions.splice(index, 1);
  });
}

/**
 * Registers a default value for a param.
 * @param {MaybeRef<String|RegExpConstructor>} name Matches the name of a GET parameter.
 * @param {MaybeRef<any>} defaultValue A default value for the GET parameter.
 */
export function useFilterClear(name, defaultValue) {
  const { defaultValues } = inject(privateSymbol);
  const nameRef = computed(() => unref(name));

  if (typeof nameRef.value !== 'string' && !(nameRef.value instanceof RegExp)) {
    // eslint-disable-next-line no-console
    console.warn(`useFilterClear: invalid filter param: ${nameRef.value}`);
  }

  const item = { name: nameRef, defaultValue };

  defaultValues.push(item);

  onUnmounted(() => {
    const index = defaultValues.indexOf(item);
    defaultValues.splice(index, 1);
  });
}
