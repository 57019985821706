<script setup>
import useVuelidate from '@vuelidate/core';
import { useI18n, useValidators } from '@/util';

const props = defineProps({
  isSubmitting: {
    type: Boolean,
    default: false,
  },
  tag: {
    type: Object,
    default: null,
  },
  projectId: {
    type: Number,
    default: undefined,
  },
});

const emit = defineEmits(['submit', 'close']);
const { t } = useI18n();
const { required, helpers } = useValidators();

const name = shallowRef(props.tag?.name ?? '');
const color = shallowRef(props.tag?.color ?? '');
const isProjectSpecific = shallowRef(props.tag?.projectId != null);
const colors = [
  '#d84640',
  '#f78233',
  '#f3bd38',
  '#b1da33',
  '#53c843',
  '#37cecf',
  '#2e8de3',
  '#9b7cdb',
  '#f47fbd',
  '#a6a6a6',
  '#4d4d4d',
  '#9d6857',
];

const projectId = computed(() => (isProjectSpecific.value ? (props.tag?.projectId ?? props.projectId ?? 0) : 0));

const rules = {
  name: {
    required: helpers.withMessage(t('You must enter a tag name'), required),
  },
  color: {
    required: helpers.withMessage(t('You must select a tag color'), required),
  },
};
const v$ = useVuelidate(rules, { name, color });

function selectColor(newColor) {
  color.value = newColor;
}

function submit() {
  v$.value.$touch();
  if (!v$.value.$error) {
    emit('submit', {
      name: name.value,
      color: color.value,
      projectId: projectId.value,
    });
  }
}
</script>
<template>
  <LscSheet sheetClass="w-72">
    <form @submit.prevent="submit">
      <VTextField
        v-model="name"
        :autofocus="true"
        class="mb-4"
        :label="t('Tag name')"
        :errorMessages="v$.name.$errors.map((error) => error.$message)"
      >
        <template #append-inner>
          <LscIcon
            v-if="name"
            class="cursor-pointer"
            :color="'var(--lsds-a-color-icon-default)'"
            icon="lsi-close"
            size="sm"
            @click="$emit('close')"
          />
        </template>
      </VTextField>
      <h4 class="mb-3 text-body-1 font-semibold">{{ t('Tag color') }}</h4>
      <div class="grid grid-cols-6 justify-center justify-items-center gap-3">
        <button
          v-for="c of colors"
          :key="c"
          type="button"
          class="flex size-8 items-center justify-center"
          @click.stop="selectColor(c)"
        >
          <LscIcon size="xl" :color="c" :icon="c === color ? 'lsi-mark-read' : 'lsi-color'" />
        </button>
      </div>
      <div v-if="v$.color.$invalid" class="px-4 py-1.5 text-body-2 text-[color:--lsds-a-color-action-critical-default]">
        {{ v$.color.$errors.map((error) => error.$message).join() }}
      </div>

      <div class="mt-5 flex flex-col gap-8">
        <div class="mb-2 flex w-full items-center justify-between">
          <VCheckbox v-model="isProjectSpecific" density="compact" :trueValue="true" :label="t('Project specific')" />
        </div>
        <div class="text-center">
          <LscButton variant="primary" :loading="isSubmitting" type="submit">
            {{ tag?.id ? t('Save changes') : t('Create new tag') }}
          </LscButton>
        </div>
      </div>
    </form>
  </LscSheet>
</template>
