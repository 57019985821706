<script setup>
import { useTagActions } from '@/api';
import LswTagPickerMenu from './LswTagPickerMenu.vue';

const props = defineProps({
  projectId: {
    type: Number,
    default: undefined,
  },
  tags: {
    type: Array,
    default: () => [],
  },
  closeOnSelect: {
    type: Boolean,
    default: true,
  },
});

const emit = defineEmits(['update:tags', 'update:modelValue']);
const { createTag } = useTagActions();

function normalizeTagData({ color, id, name, projectId }) {
  return {
    color,
    id: parseInt(id, 10),
    name,
    project: { id: parseInt(projectId, 10), type: 'projects' },
    projectId: parseInt(projectId, 10),
  };
}

async function createNewTag(tag) {
  const { data } = await createTag({ tag });
  emit('update:tags', [...props.tags, normalizeTagData(data)]);
}

function handleClose() {
  if (props.closeOnSelect) {
    emit('update:modelValue', false);
  }
}
</script>

<template>
  <WidgetMenu :closeOnContentClick="false" :offset="8" @update:modelValue="emit('update:modelValue', $event)">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <LswTagPickerMenu
      :projectId="projectId"
      :tags="tags"
      @update:tags="emit('update:tags', $event)"
      @close="handleClose"
      @createNewTag="createNewTag"
    />
  </WidgetMenu>
</template>
