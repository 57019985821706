import { usePendo } from '@/api';

/**
 * Provides functions for tracking Pendo events related to Reports.
 * Measurement plan: https://digitalcrew.teamwork.com/spaces/product-analytics/page/42288-reports-phase-i
 */

export function useReportTracking() {
  const {
    trackPendoEvent,
    EVENT_CATEGORIES: { DISCOVERY, ADVANCED_DISCOVERY, ACTIVATION },
  } = usePendo();

  const EVENT_NAME = 'REPORTS_EVENT';
  const COMMON_METRICS = ['plan_name', 'user_role', 'page', 'on_trial', 'is_icp', 'is_paid'];
  const categoryMap = {
    discovery: DISCOVERY,
    advanced: ADVANCED_DISCOVERY,
    activation: ACTIVATION,
  };

  function track(metadata) {
    trackPendoEvent({
      eventName: EVENT_NAME,
      commonMetrics: COMMON_METRICS,
      metadata,
    });
  }

  function trackReportsEvent(pageType, eventAction, eventCategory, ...args) {
    const selectedCategory = categoryMap[eventCategory];

    const trackingData = {
      page_type: pageType,
      event_action: eventAction,
      event_category: selectedCategory,
    };

    switch (eventAction) {
      case 'reports_viewed': {
        track(trackingData);
        break;
      }
      case 'report_opened':
      case 'custom_report_created':
      case 'request_a_demo_clicked':
      case 'first_time_experience_viewed':
      case 'start_using_report_clicked': {
        const [reportType] = args;
        track({ ...trackingData, report_type: reportType });
        break;
      }
      default:
        break;
    }
  }

  function trackCustomReportEvent(pageType, eventAction, eventCategory, ...args) {
    const trackingData = {
      eventName: 'CUSTOM_REPORT_EVENT',
      commonMetrics: COMMON_METRICS,
      report_type: 'custom_reports',
      metadata: {
        page_type: pageType,
        event_action: eventAction,
        event_category: eventCategory,
      },
    };

    switch (eventAction) {
      case 'create_custom_report_clicked':
      case 'custom_report_modal_viewed':
      case 'save_custom_report_clicked':
      case 'edit_custom_report_clicked':
      case 'delete_custom_report_clicked':
      case 'delete_custom_report_modal_viewed':
      case 'confirm_delete_clicked':
      case 'cancel_clicked':
      case 'edit_custom_report_modal_viewed':
      case 'refresh_custom_report_clicked':
      case 'custom_report_searched':
      case 'schedule_custom_report_clicked':
      case 'custom_report_viewed':
      case 'create_new_report_clicked':
      case 'request_a_demo_clicked':
      case 'learn_more_clicked':
      case 'custom_report_report_type_viewed':
      case 'custom_report_add_columns_viewed':
      case 'custom_report_date_ranges_viewed':
      case 'custom_report_add_users_viewed': {
        trackPendoEvent(trackingData);
        break;
      }
      case 'custom_report_error_message_displayed': {
        const [errorMessage] = args;
        trackingData.metadata.error_message = errorMessage;
        trackPendoEvent(trackingData);
        break;
      }
      case 'custom_report_exported': {
        const [fileType] = args;
        trackingData.metadata.file_type = fileType;
        trackPendoEvent(trackingData);
        break;
      }
      case 'report_exported': {
        const [exportType, reportId] = args;
        trackingData.metadata.export_type = exportType;
        trackingData.metadata.report_id = reportId;
        trackPendoEvent(trackingData);
        break;
      }
      case 'custom_report_created':
      case 'update_report_clicked': {
        const [reportType, dateRange, filtersApplied, usersAdded] = args;
        trackingData.metadata.report_type = reportType;
        trackingData.metadata.date_range = dateRange;
        trackingData.metadata.filters_applied = filtersApplied;
        trackingData.metadata.users_added = usersAdded;
        trackPendoEvent(trackingData);
        break;
      }
      case 'custom_report_report_type_applied': {
        const [reportType] = args;
        trackingData.metadata.report_type = reportType;
        trackPendoEvent(trackingData);
        break;
      }
      case 'custom_report_date_range_applied': {
        const [dateRange] = args;
        trackingData.metadata.date_range = dateRange;
        trackPendoEvent(trackingData);
        break;
      }
      case 'custom_report_users_added': {
        const [usersAdded] = args;
        trackingData.metadata.users_added = usersAdded;
        trackPendoEvent(trackingData);
        break;
      }
      default:
        break;
    }
  }

  function trackFeatureTrialEvent(eventAction) {
    trackPendoEvent({
      eventName: 'FEATURE_TRIAL_EVENT',
      commonMetrics: ['plan_name', 'user_role', 'page'],
      metadata: {
        page_type: 'profitability_introduction',
        event_action: eventAction,
        fte_type: 'old_fte',
        trial_available: true,
      },
    });
  }

  function trackUpgradeSlateEvent(feature, eventAction, eventCategory) {
    trackPendoEvent({
      eventName: 'UPGRADE_SLATE_EVENT',
      commonMetrics: ['plan_name', 'user_role', 'page'],
      metadata: {
        page_type: 'upgrade_slate',
        event_action: eventAction,
        event_category: categoryMap[eventCategory],
        feature,
      },
    });
  }

  return {
    trackCustomReportEvent,
    trackReportsEvent,
    trackFeatureTrialEvent,
    trackUpgradeSlateEvent,
  };
}
