<script setup>
import { JobRolesAddDialog } from '@/module/jobRoles';
import LswRolePickerMenuCard from './LswRolePickerMenuCard.vue';

defineProps({
  dataTestIdPrefix: {
    type: String,
    default: undefined,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  people: {
    type: Array,
    default: () => [],
  },
});

const modelValue = defineModel({
  type: Boolean,
  default: false,
});

const roles = defineModel('roles', {
  type: Array,
  default: () => [],
});

const isDialogOpen = shallowRef(false);
</script>

<template>
  <WidgetMenu v-bind="$attrs" v-model="modelValue" :disabled="disabled">
    <template #activator="activator">
      <slot name="activator" v-bind="activator" />
    </template>
    <LswRolePickerMenuCard v-model:roles="roles" :dataTestIdPrefix @addRole="isDialogOpen = true" />
  </WidgetMenu>

  <JobRolesAddDialog v-if="isDialogOpen" v-model="isDialogOpen" :canSaveAndAddAnother="false" :people="people" />
</template>
