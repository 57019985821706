// An internal composable used by the LscTable* components.

const symbol = Symbol('useLscTable');

/**
 * @typedef {object} LscTableOrder
 * @property {string} orderBy
 * @property {'asc'|'desc'} orderMode - 'asc' or 'desc'
 */

/**
 * @typedef {object} LscTableColumn
 * @property {string|number} id A unique column ID.
 * @property {string} name The column name for display.
 * @property {string?} tooltip The tooltip for the column.
 * @property {Boolean} enabled  Should this column be displayed.
 * @property {string?} orderBy The key to use for ordering.
 * @property {number} width The ideal column width.
 * @property {number} minWidth The minimum column width. Used for resizing.
 * @property {number} colspan How many columns should the column span.
 * @property {'left'|'right'|'center'} align The column alignment
 * @property {string} class The class to add to the column
 * @property {boolean} resizable Should this column be resizable.
 * @property {string?} slot The slot name to use for the column. This is optional, and will default to column.id if not defined.
 */

/**
 * @type {LscTableColumn}
 */
const defaultColumn = {
  id: undefined,
  name: undefined,
  tooltip: undefined,
  enabled: true,
  orderBy: undefined,
  width: 80,
  minWidth: 80,
  align: 'left',
  type: undefined,
  resizable: false,
  slot: undefined,
};

const spacerColumn = {
  id: -1,
  spacer: true,
  enabled: true,
};

/**
 * @param {object} LscTableParams
 * @param {ShallowRef<LscTableColumn[]>} LscTableParams.columns
 * @param {ShallowRef<LscTableOrder?>} LscTableParams.order
 * @param {MaybeRefOrGetter<Boolean>} LscTableParams.proportional
 * @param {MaybeRefOrGetter<Number>} LscTableParams.stickyFirstColumns
 * @param {MaybeRefOrGetter<Number>} LscTableParams.stickyLastColumns
 */
function LscTable({
  columns: _columns,
  order: _order,
  proportional,
  colWidths,
  stickyFirstColumns,
  stickyLastColumns,
} = {}) {
  const columns = computed({
    get: () => {
      // Filter out the disabled columns
      const normalizedColumns = _columns.value.filter((column) => column.enabled !== false);

      if (!proportional.value) {
        // Add a spacer column before the last sticky column or at the end of the list
        if (stickyLastColumns.value) {
          normalizedColumns.splice(-1 * stickyLastColumns.value, 0, spacerColumn);
        } else {
          normalizedColumns.push(spacerColumn);
        }
      }

      return normalizedColumns.map((column, index) => {
        const stickyFirstColumn = index < stickyFirstColumns.value;
        const stickyLastColumn = index >= normalizedColumns.length - stickyLastColumns.value;
        return {
          ...defaultColumn,
          ...column,
          index,
          stickyLastColumn,
          stickyFirstColumn,
          applyShadowStart: index === stickyFirstColumns.value - 1,
          applyShadowEnd: index === normalizedColumns.length - stickyLastColumns.value,
          draggable: stickyFirstColumn || stickyLastColumn || column.spacer ? false : column.draggable,
          resizable: stickyLastColumn || column.spacer ? false : column.resizable,
          slot: column.slot ?? column.id,
        };
      });
    },
    set: (value) => {
      // eslint-disable-next-line no-param-reassign
      _columns.value = value.filter((column) => !column.spacer);
    },
  });

  const order = computed({
    get: () => _order.value,
    set: (value) => {
      // eslint-disable-next-line no-param-reassign
      _order.value = value;
    },
  });

  /**
   * @param {LscTableColumn} column
   */
  function toggleOrder(column) {
    if (!column.orderBy || !order.value) {
      return;
    }
    if (order.value.orderBy === column.orderBy) {
      order.value = {
        orderBy: column.orderBy,
        orderMode: order.value.orderMode === 'asc' ? 'desc' : 'asc',
      };
    } else {
      order.value = {
        orderBy: column.orderBy,
        orderMode: 'asc',
      };
    }
  }

  function clearOrder() {
    order.value = {
      orderBy: undefined,
      orderMode: undefined,
    };
  }

  function getAriaSort(column) {
    // We render td elements for empty columns, so we need to check if the column has a name
    if (!column.name) {
      return undefined;
    }
    if (!column.orderBy || !isOrderedBy(column)) {
      return 'none';
    }

    return order.value.orderMode === 'asc' ? 'ascending' : 'descending';
  }

  function isOrderedBy(column) {
    return order.value && column.orderBy && order.value.orderBy === column.orderBy;
  }

  function calculateGridColumn(column) {
    if (proportional.value) {
      if (column.resizable) {
        return `${column.width}px`;
      }
      return `minmax(${column.width}px, ${column.width}fr)`;
    }
    return column.spacer ? 'minmax(0, 1fr)' : `${column.width}px`;
  }

  function canMoveColumnLeft(column) {
    return column.draggable && column.index > stickyFirstColumns.value;
  }

  function canMoveColumnRight(column) {
    return column.draggable && column.index < columns.value.length - stickyLastColumns.value - 2;
  }

  function moveColumnToIndex(column, targetIndex) {
    const currentIndex = columns.value.findIndex((c) => c.id === column.id);
    if (currentIndex === targetIndex) {
      return;
    }

    const newColumns = [...columns.value];
    newColumns.splice(currentIndex, 1);
    newColumns.splice(targetIndex, 0, column);
    columns.value = newColumns;
  }

  function moveColumn(column, direction) {
    const currentIndex = columns.value.findIndex(({ id }) => id === column.id);
    const targetIndex = currentIndex + direction;
    if (
      !column.draggable ||
      (targetIndex < currentIndex && !canMoveColumnLeft(column)) ||
      (targetIndex > currentIndex && !canMoveColumnRight(column))
    ) {
      return;
    }
    const columnToReplace = columns.value[targetIndex];

    if (!columnToReplace.draggable || columnToReplace.stickyFirstColumn || columnToReplace.stickyLastColumn) {
      return;
    }

    moveColumnToIndex(column, targetIndex);
  }

  return {
    calculateGridColumn,
    canMoveColumnLeft,
    canMoveColumnRight,
    clearOrder,
    columns,
    colWidths,
    getAriaSort,
    isOrderedBy,
    moveColumn,
    order,
    stickyFirstColumns,
    stickyLastColumns,
    toggleOrder,
  };
}

/**
 *
 * @type {LscTable}
 */
export function provideLscTable(config) {
  const lscTable = LscTable(config);
  provide(symbol, lscTable);
  return lscTable;
}

/**
 * @type {LscTable}
 */
export function useLscTable() {
  return inject(symbol);
}
